<template>
  <div class="pop_container">
    <div class="title_area" v-if="'Issued' != data.status">AVAILABILE QUANTITY {{ data.status }}</div>
    <div class="title_area" v-else>Reference ID</div>
    <div class="body_area">
      <template v-if="'Issued' != data.status">
      <div class="form_section_generate_slip">
        <table class="change_pass_table">
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                   Quantity
                  <span></span>
                </label><br>
                <input type="text" class="text-width" v-model="data.total_qty" disabled  />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                 Requested Quantity
                  <span></span>
                </label><br>
                <input type="text" class="text-width" v-model="data.requested_qty" />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="label_and_element_wrapper">
                <label>
                   Receiver
                  <span></span>
                </label><br>
                <input type="text" class="text-width" v-model="data.receiver_name" />
              </div>
            </td>
          </tr>
          <template v-if="data.postal_document_type.reference_required">
              <tr v-for="qty in parseInt(data.requested_qty)" :key="qty">
                  <td>
                      <div class="label_and_element_wrapper">
                          <label>
                              Reference Number {{ qty }}
                          </label><br>
                          <input type="text" class="text-width" v-model="references[qty - 1]">
                      </div>
                  </td>
              </tr>
        </template>
        </table>
        <div class="pop_buttons_container">
          <a class="tr_cancel" @click="closeModal">Cancel</a>
          <a class="tr_save" @click="updateStock">Confirm</a>
        </div>
      </div>
      </template>
      <template v-else>
        <table style="margin:auto;">
            <tr v-for="(ref, qty) in data.references" :key="qty">
                <td>
                    <div class="label_and_element_wrapper">
                        <label>
                            Reference Number {{ qty + 1 }}
                        </label>
                        <input type="text" class="text-width" :value="ref.reference_number" disabled>
                    </div>
                </td>
            </tr>
        </table>
        <div class="pop_buttons_container">
          <a class="tr_cancel" @click="closeModal">Close</a>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "popUpdatePostalCount",
  data() {
    return {
      references: [],
    }
  },
  created(){

    const branch_stock = this.data.postal_document_type.branch_stocks.filter((val) => {
      if(val.branch_id == this.data.branch_id) {
        return val;
      }
    })


    if(branch_stock.length > 0) {
      this.data.total_qty = branch_stock[0].quantity;
    }
  },
  methods: {
     closeModal() {
       this.modalStatus = !this.modalStatus;
      this.$emit("closeModal", this.modalStatus);
    },

    updateStock(){
      if(this.data.total_qty >= this.data.requested_qty)
      {
        if (this.references.length) {
          this.data.references = this.references
        }
        this.axios.post("/api/postalPrepaid/status/"+this.data.id,this.data)
      .then(response => {
        this.toast.success(response.data.msg);
        this.$router.go(this.$router.currentRoute);
      })
    
      .catch(error =>{
          console.log(error);
      });
    }

    else
      {
        this.toast.error("Insufficient stock to handle the request");
        
      }

  }
  },
  props: ["data"]
};
</script>
<style lang="scss" scoped>
.form_section_generate_slip table.change_pass_table {
  width: 400px;
  margin: auto;
  td {
    text-align: left;
    padding: 0;
    label {
      padding: 0;
      height: auto;
    }
  }
}
.text-width {
  width:100% !important;
}
.body_area {
  overflow-y: scroll;
  max-height: 500px;
}
</style>